<template>
	<div class="cbox">
		<div class="headbar">
			<div style="float: right;display: flex;">
				<div>
					<el-input v-model="formData.plan_name" size="small" placeholder="计划名称"
						style="max-width: 150px; margin-right: 10px;" />
					<el-date-picker v-model="formData.sta_end_time" size="small" type="datetimerange" range-separator="至"
						start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd HH:mm:ss"
						style="width: 350px; margin-right: 10px; margin-left: 10px">
					</el-date-picker>
					<el-button size="small" icon="el-icon-search" @click="onSearch" plain type="primary">查询
					</el-button>
					<el-button size="small" icon="el-icon-refresh" @click="onReset" type="warning">重置
					</el-button>
					<el-button type="primary" size="small" icon="el-icon-plus" @click="onAdd">添加
					</el-button>
				</div>


			</div>
			<div style="padding-top: 14px">
				<el-breadcrumb>
					<el-breadcrumb-item>问卷调查</el-breadcrumb-item>
					<el-breadcrumb-item>问卷计划</el-breadcrumb-item>
				</el-breadcrumb>
			</div>
		</div>
		<div class="bcontent">



			<el-table v-tableFit border stripe :data="dataList" style="width: 100%; margin-top: 20px; color: #000">
				<el-table-column type="index" label="序号" width="60"></el-table-column>
				<el-table-column prop="plan_name" label="名称" min-width="120"></el-table-column>
				<el-table-column prop="create_name" label="发布人" width="150"></el-table-column>
				<el-table-column prop="sta_time" label="问卷开始日期" width="200"></el-table-column>
				<el-table-column prop="end_time" label="调查截止日期" width="200"></el-table-column>
				

				<!-- <el-table-column prop="qrcode" label="二维码" width="80">
					<template slot-scope="scope">

						<el-image v-if="scope.row.qrcode" style="width:25px;height:25px" :src="scope.row.qrcode" fit="fit" :preview-src-list="[scope.row.qrcode]"></el-image>
						<el-button v-else size="small" @click="createQrcode(scope.row)">生成</el-button>
					</template>

				</el-table-column> -->

				<!--				<el-table-column prop="percentage" label="完成情况" width="100"></el-table-column>-->
			

				<el-table-column prop="" label="二维码" width="100">
					<template slot-scope="scope">

						
						<div :id="'qrcode_'+scope.row.id" v-if="scope.row.qrcode" class="qrcode" @click="downImage(scope.row)"
							style="width: 60px; height: 60px;">
							<img v-if="scope.row.qrcode.indexOf('qushixi')<0"  :src="scope.row.qrcode" style="width:60px;height:60px" />
						</div>
					</template>

				</el-table-column>

				<el-table-column label="操作" width="220">
					<template slot-scope="scope">
						<div>
							
							<el-tag type="success" size="mini" @click="onReport(scope.row)"
								style="margin-left: 10px;cursor: pointer;">参与报告
							</el-tag>
							

						</div>
					</template>
				</el-table-column>
			</el-table>
			<el-pagination layout="total, prev, pager, next" @size-change="handleSizeChange"
				@current-change="handleCurrentChange" :current-page.sync="page.current_page" :page-size="page.per_page"
				:total="page.count">
			</el-pagination>
		</div>

		<reportjoin v-if="showJoinReport" :plan="formData"></reportjoin>
	</div>
</template>

<script>
import QRCode from 'qrcodejs2'
import reportjoin from "./report_join_bzr"
export default {
	components: {  reportjoin },
	data() {
		return {
			dialogVisible: false,
			target_teachers: "",
			target_classes: "",
			choosedTeachers: 0,
			choosedClasses: 0,

			page: {
				count: 0,
				current_page: 1,
				per_page: 20,
			},

			dataList: [],
			formData: {},
			ruleForm: {
				sort: 1,
				formal: 1,
				moban_arr: [{
					type: 0,
					send_type: [0, 1, 2],
					checkAll: true,
					isIndeterminate: false,
					selectCoList: [],
				}],
			},
			// isIndeterminate: false,
			// checkAll: true,
			showDialog_co: false,
			coList: [],
			indexCo: 0,
			// selectCoList:[],
			colorList: ['#67c23a', '#f56c6c', '#409eff',],
			rules: {
				plan_name: [{
					required: true,
					message: "请输入计划名称",
					trigger: "blur"
				},],
				sta_end_time: [{
					required: true,
					message: "请选择调查时段",
					trigger: "blur"
				},],
				formal: [{
					required: true,
					message: "请选择发布状态",
					trigger: "blur"
				},],
			},
			showDialog: false,
			showSms: false,
			dialodTitle: '问卷计划新增',
			moban_list00: [], //学生
			moban_list02: [], //企业
			moban_list01: [], //家长
			props: {
				multiple: true,
				emitPath: false,
				value: 'id',
				label: 'stu_name'
			},

			studentsTree: [],
			showDialog_preview: false,
			showDialog_complete: false,
			viewTitle: '',
			showDialog_report: false,
			showDialog_report2: false,
			reportTitle: '',
			active_report: 'first',
			tempList: [],
			ids: [],
			tmOne: {},

			list: [],
			gkInfo: {},
			tjTmlist: [],
			hasXX: false,
			wordsList: [],
			ckdViewIvstID: [],
			plan_id: 0,
			completeList: [],
			defaultProps: {
				children: 'arr',
				label: 'name'
			},
			rateList: [],
			expandedKeys: [],
			teacherInfo: {},
			showJoinReport: false
		};
	},
	mounted() {
		// console.log(sessionStorage.getItem("auth"));
		
		this.getlist();
		
		//this.getStudentsTree()
		//this.getCoList()

	},
	methods: {
		getInfo() {
			this.$http.post("/api/get_teacher_info").then(res => {
				this.teacherInfo = res.data
			})
		},
	
		getlist() {
			this.dataList = []
			this.$http
				.post("/api/by_ivst_plan_list_bzr", {
					page: this.page.current_page,
					pagesize: this.page.per_page,
					plan_name: this.formData.plan_name,
					sta_time: this.formData.sta_end_time ? this.formData.sta_end_time[0] : '',
					end_time: this.formData.sta_end_time ? this.formData.sta_end_time[1] : '',
					sta_end_time: this.formData.sta_end_time,
				})
				.then((res) => {
					if (res.data && res.data && res.data.length > 0) {
						let rData = res.data
						
						this.dataList = rData;
					

						this.$nextTick(() => {
							this.creatQrCode()
						})
					}
				});
		},
		creatQrCode() {
			for (let item of this.dataList) {
				if (item.qrcode && item.qrcode.indexOf("qushixi") > 0) {
					let qrcodetxt = item.qrcode 
					// console.log(526,qrcodetxt)
					var qrcode = new QRCode("qrcode_" + item.id, {
						text: qrcodetxt, // 需要转换为二维码的内容
						width: 500,
						height: 500,
						colorDark: '#000000',
						colorLight: '#ffffff',
						correctLevel: QRCode.CorrectLevel.H
					})
				}

			}

		},
		getMobanArrs() {
			this.$http.post("/api/ivst_list", {
				page: 1,
				pagesize: 10000,
			})
				.then((res) => {

					this.moban_list00 = res.data.data
					return
					if (res.data.data && res.data.data.length > 0) {
						let groupArr = this.groupBy(res.data.data, function (item) {
							return [item.target_user]
						})
						let moban_list00 = [],
							moban_list02 = [],
							moban_list01 = []
						if (groupArr && groupArr.length > 0) {
							groupArr.map(a => {
								if (a[0].target_user == 'student') {
									moban_list00 = a
								}
								if (a[0].target_user == 'enterpirse') {
									moban_list02 = a
								}
								if (a[0].target_user == 'parent') {
									moban_list01 = a
								}

								this.moban_list00 = moban_list00
								this.moban_list02 = moban_list02
								this.moban_list01 = moban_list01

							})
						}
					}
				});
		},
		groupBy(array, f) {
			let groups = {};
			array.forEach(function (o) {
				let group = JSON.stringify(f(o));
				groups[group] = groups[group] || [];
				groups[group].push(o);
			});
			return Object.keys(groups).map(function (group) {
				return groups[group];
			});
		},

		getStudentsTree() {
			this.$http.post("/api/by_students_tree").then((res) => {
				if (res.data && res.data.length > 0) {
					this.studentsTree = res.data
				}
			});
		},
		getCoList() {
			let _this = this
			_this.$http.post("/api/by_enterprise_list").then((res) => {
				if (res.data.data && res.data.data.length > 0) {
					_this.coList = res.data.data
					_this.ruleForm.moban_arr[0].selectCoList = res.data.data
				}
			});
		},
		onSearch() {
			this.getlist();
		},
		onReset() {
			this.formData = {};
			this.page.current_page = 1;
			this.getlist();
		},
		handleSizeChange(val) {
			// console.log(`每页 ${val} 条`);
		},
		handleCurrentChange(val) {
			this.page.current_page = val;
			this.getlist();
		},
		onAdd() {
			this.ruleForm = {
				sort: 1,
				formal: 1,
				moban_arr: [{
					type: 0,
					send_type: [0, 1, 2],
					checkAll: true,
					isIndeterminate: false,
					selectCoList: this.coList,
				}],

			}
			this.target_classes = "";
			this.target_teachers = "";
			this.choosedClasses = 0;
			this.choosedTeachers = 0

			this.showDialog = true

		},
		onModify(a) {
			this.$http.post("/api/ivst_plan", { plan_id: a.id, edit: 1 }).then(res => {
				let e = res.data


				e.moban_arr = e.temp
				this.dialodTitle = '问卷计划修改'
				let info = JSON.parse(JSON.stringify(e))
				let sta_end_time = [];
				sta_end_time.push(new Date(e.sta_time));
				sta_end_time.push(new Date(e.end_time));
				info.sta_end_time = sta_end_time
				console.log(info)
				if (info.moban_arr && info.moban_arr.length > 0) {
					info.moban_arr.map((a, index) => {
						this.target_classes = a.target_classes ? a.target_classes : ""
						this.target_teachers = a.target_teachers ? a.target_teachers : ""
						this.choosedTeachers = 0, this.choosedClasses = 0;
						if (this.target_teachers.indexOf(",") > 0) {
							this.choosedTeachers = this.target_teachers.split(",").length - 2
						} else if (this.target_teachers > 0) {
							this.choosedTeachers = 1
						}
						if (this.target_classes.indexOf(",") > 0) {
							this.choosedClasses = this.target_classes.split(",").length - 2
						} else if (this.target_classes > 0) {
							this.choosedClasses = 1
						}





					})
				} else {
					info.moban_arr = []
					this.target_classes = ""
					this.target_teachers = ""

					this.choosedTeachers = 0
					this.choosedClasses = 0

				}

				this.ruleForm = {
					...info
				};
				this.showDialog = true;

			})

		},
		onViewComplete(e) {
			// console.log(e)
			this.$http.post("/api/get_ivst_dtqk", {
				plan_id: e.id,
				pmid: 1,
			}).then(res => {
				// console.log(res.data.data)
				this.viewTitle = e.plan_name
				this.showDialog_complete = true
				let ok = 0, no = 0, rateList = [], list = []
				res.data.data && res.data.data.map((a, ia) => {
					a.index = ia
					a.type = 'dept'
					list = []
					a.arr[0].arr && a.arr[0].arr.map(b => {
						ok = 0, no = 0
						b.arr && b.arr.map(ss => {
							// console.log(ss)
							if (ss.id) {
								ok += 1
							} else {
								no += 1
							}
						})
						b.ok = ok
						b.no = no
						b.total = parseInt(ok) + parseInt(no)
						if (b.total == 0) {
							b.rate = 0
						} else {
							b.rate = parseFloat((ok / b.total) * 100).toFixed(2)
						}
						list.push(b)
					})
					rateList.push({
						name: a.name,
						index: a.index,
						list: list
					})
				})
				this.completeList = res.data.data
				this.rateList = rateList
				// console.log(740, rateList)
				this.$nextTick(() => {
					this.expandedTree(res.data.data)
					for (let item of rateList) {
						// console.log('dept' + item.index)
						let myChart = this.$echarts.init(document.getElementById('dept' + item.index))
						myChart.clear();
						let option = {
							color: this.colorList,
							tooltip: {
								trigger: 'axis',
								axisPointer: {
									type: 'cross',
									crossStyle: {
										color: '#999'
									}
								}
							},
							title: [{
								text: item.name
							}],

							legend: {
								data: ['已完成人数', '未完成人数', '完成百分比']
							},
							xAxis: [{
								type: 'category',
								data: item.list.map(e => {
									return e.name
								}),
								axisPointer: {
									type: 'shadow'
								}
							}],
							yAxis: [{
								type: 'value',
								name: '人数',
								min: 0,
								max: 100,
								axisLabel: {
									formatter: '{value} 人'
								}
							},

							{
								type: 'value',
								name: '百分比',
								min: 0,
								max: 100,
								axisLabel: {
									formatter: '{value} %'
								}
							}
							],
							series: [{
								name: '已完成人数',
								type: 'bar',
								data: item.list.map(e => {
									return e.ok
								}),
							},
							{
								name: '未完成人数',
								type: 'bar',
								data: item.list.map(e => {
									return e.no
								}),
							},
							{
								name: '完成百分比',
								type: 'bar',
								data: item.list.map(e => {
									return e.rate
								}),
							}
							]
						};
						myChart.setOption(option)
					}
				})


			})
		},

		renderContent(h, { node, data, store }) {
			// console.log('1h',h,'2node',node,'3data', data,'4store',store)
			return (
				<div class="custom-tree-node" style="width:100%;">
					<div class="flexBetween" style="width:100%;">
						<div className="flexStart" style="width:100%;">
							<span style="min-width:80px;">{data.stu_name ? data.stu_name : node.label}</span>
							<span style="color:#ff8400;">{data.stu_name && !data.id ? '未填写' : ''}</span></div>
						{data.type ? <el-tag onClick={(e) => this.onCopy(e, data)} size="mini">一键复制未完成名单</el-tag> : ''}
					</div>
				</div>
			);
		},
		onCopy(e, data) {
			e.preventDefault()
			// console.log(data)
			let text = ''
			text = `${data.name}未填写人员名单：`
			data.arr[0].arr && data.arr[0].arr.map(a => {
				let flag = 0
				a.arr && a.arr.map(b => {
					if (!b.id) {
						flag = 1
					}
				})
				if (flag) {
					text = `${text}
班级：${a.name}`
					a.arr && a.arr.map(b => {
						if (!b.id) {
							text = `${text}
          ${b.stu_name}`
						}
					})
				}
			})
			// this.handleClipboard(data)


			// console.log(123456,text)


			let textareaEl = document.createElement('textarea')  // 创建一个元素
			textareaEl.value = text   // 将需要复制的内容传给该元素作为文本
			document.body.appendChild(textareaEl)
			textareaEl.select()   // 自动选中
			document.execCommand('copy')  // 这个命令会将选中的内容复制到粘贴板中
			document.body.removeChild(textareaEl)

		},
		onView(e) {
			console.log(e)
			this.$http.post("/api/ivst_plan", {
				plan_id: e.id,
				edit: 1
			}).then(res => {
				this.tempList = res.data.temp
				this.viewTitle = res.data.plan_name
				this.showDialog_preview = true

				this.changeIvst(this.tempList[0])
			})
		},
		changeIvst(item) {
			this.ckdViewIvstID = [item.ivst_id + ""]

			this.$http.post("/api/get_ivst_tjinfo", {
				plan_id: item.plan_id,
				ivst_id: item.ivst_id
			}).then(res => {
				this.tjTmlist = res.data
				this.analysisOne(this.tjTmlist[0])
			})
		},

		analysisOne(c, index) {



			let arr_y = [],
				data = []
			this.tmOne = c
			this.tmOne.indexC = index
			if (c.tm_type.indexOf("radio") >= 0 || c.tm_type.indexOf("checkbox") >= 0) {
				this.hasXX = true
				this.$nextTick(() => {
					this.initChart(c)
				})

			} else {
				this.hasXX = false
				this.wordsList = c.answers;
			}
		},
		initChart(c) {
			let _this = this
			let myChart1 = this.$echarts.init(document.getElementById('mychart'))
			let colorList = ['#73c0de', '#91cc75', '#fac858', '#ee6666', '#3ba272',
				'#fc8452', '#9a60b4', '#ea7ccc', '#5470c6'
			]
			let data = c

			myChart1.clear();
			let option = {

				tooltip: {
					trigger: 'axis',
					axisPointer: {
						type: 'shadow'
					}
				},

				grid: {
					left: '3%',
					right: '4%',
					bottom: '3%',
					containLabel: true
				},
				xAxis: {
					type: 'value',
					boundaryGap: [0, 0.01]
				},
				yAxis: {
					type: 'category',
					data: data.tm_content.map(e => {
						return e.title
					})
				},
				series: [{
					name: '选择人数',
					type: 'bar',

					itemStyle: {
						color: function (p) {
							return colorList[p.dataIndex]
						}
					},
					data: data.tm_content.map(e => {
						return e.choose_count
					})
				},

				]
			};
			myChart1.setOption(option)
			window.document.getElementById('mychart').style.height = data.tm_content.length * 50 + 100 + 'px'
			myChart1.resize()
		},
		initChartTM(data) {
			let colorList = ['#73c0de', '#91cc75', '#fac858', '#ee6666', '#3ba272',
				'#fc8452', '#9a60b4', '#ea7ccc', '#5470c6'
			]
			let _this = this
			let myChart1 = this.$echarts.init(document.getElementById('chartitem1'))
			myChart1.clear();
			let option = {
				title: {
					text: '选项统计',
					textAlign: "center",
					x: 'center',
					y: 'top',
				},
				tooltip: {
					trigger: 'axis',
					axisPointer: {
						type: 'shadow'
					}
				},

				grid: {
					left: '3%',
					right: '4%',
					bottom: '3%',
					containLabel: true
				},
				xAxis: {
					type: 'value',
					boundaryGap: [0, 0.01]
				},
				yAxis: {
					type: 'category',
					data: data.tm_content.map(e => {
						return e.title
					})
				},
				series: [{
					name: '选择人数',
					type: 'bar',
					barWidth: 30,
					itemStyle: {
						color: function (p) {
							return colorList[p.dataIndex]
						}
					},
					data: data.tm_content.map(e => {
						return e.choose_count
					})
				},

				]
			};
			myChart1.setOption(option)


			let myChart2 = this.$echarts.init(document.getElementById('chartitem2'))
			myChart2.clear();
			let option2 = {
				tooltip: {
					trigger: 'item'
				},
				legend: {
					top: '5%',
					left: 'center'
				},

				color: colorList,
				series: [{
					name: '选择人数',
					type: 'pie',
					radius: ['40%', '70%'],
					avoidLabelOverlap: false,
					itemStyle: {
						borderRadius: 10,
						borderColor: '#fff',
						borderWidth: 2
					},
					label: {
						show: false,
						position: 'center'
					},
					emphasis: {
						label: {
							show: true,
							fontSize: '40',
							fontWeight: 'bold'
						}
					},
					labelLine: {
						show: false
					},
					data: data.tm_content.map(e => {
						return {
							value: e.choose_count,
							name: e.title
						}
					})


				}]
			};
			myChart2.setOption(option2)

			let myChart3 = this.$echarts.init(document.getElementById('chartitem3'))
			myChart3.clear();
			let option3 = {
				tooltip: {
					trigger: 'axis',
					axisPointer: {
						type: 'shadow'
					}
				},
				grid: {
					left: '3%',
					right: '4%',
					bottom: '10%',
					containLabel: true
				},

				xAxis: {
					type: 'category',
					axisLabel: {
						interval: 0,
						rotate: 40
					},
					data: data.tm_content.map(e => {
						return e.title
					})
				},
				yAxis: {
					type: 'value'
				},

				series: [{
					data: data.tm_content.map(e => {
						return e.choose_count
					}),
					type: 'line',
					smooth: true
				}]
			};
			myChart3.setOption(option3)
		},


		onDel(e) {
			this.$http.post("/api/by_ivst_plan_delete", {
				id: e.id,
			}).then(res => {
				this.$message('删除成功！')
				this.page.current_page = 1;
				this.getlist();
			});
		},
		onReport(e) {
			this.formData = e
			this.showJoinReport = true

		},
		tabClick(e) {

			if (this.active_report != "first") {
				let tmid = this.active_report.replace("idx_", "")
				let tm = null;
				for (let item of this.tjTmlist) {
					if (item.id == tmid) {
						tm = item;
						break
					}
				}

				if (tm) {
					if (tm.tm_type.indexOf("radio") >= 0 || tm.tm_type.indexOf("checkbox") >= 0) {

						this.hasXX = true
						this.$nextTick(() => {
							this.initChartTM(tm)
						})
					} else {
						this.hasXX = false
						this.wordsList = tm.answers
					}


				}
			} else {

			}

		},

		addTemplate(index) {
			let obj = {
				type: 0,
				send_type: [0, 1, 2],
				isIndeterminate: false,
				checkAll: true,
				selectCoList: this.coList,
			}
			this.ruleForm.moban_arr.push(obj)

		},
		delTemplate(index) {
			this.ruleForm.moban_arr.splice(index, 1)
		},
		changeRadio(e, index) {
			console.log(e, index)
			let _this = this
			this.indexCo = index
			this.ruleForm.moban_arr[index].ivst_id = ""
			this.ruleForm.moban_arr[index].target_user = []
			this.ruleForm.moban_arr[index].selectCoList = this.coList
			if (e == 0) {
				this.ruleForm.moban_arr[index].send_type = [0, 2, 1]
			}
			if (e == 2) {
				this.ruleForm.moban_arr[index].send_type = [2, 1]
			}
			if (e == 1) {
				this.ruleForm.moban_arr[index].send_type = [2]
			}
			this.ruleForm = {
				..._this.ruleForm
			}
		},
		changeCascader(e, index) {
			// console.log(e, index)
			this.indexCo = index
			this.ruleForm.moban_arr[index].target_user = e

		},
		chooseAllCascader(index) {
			let _this = this
			this.indexCo = index
			let arr = this.setVal()
			this.ruleForm.moban_arr[index].target_user = []
			this.ruleForm.moban_arr[index].target_user = arr
			// console.log(arr)
			this.ruleForm = {
				..._this.ruleForm
			}
		},
		//学生树全选遍历赋值
		setVal() {
			let arr = []
			this.studentsTree.map(a => {
				a.children && a.children.map(b => {
					b.children && b.children.map(c => {
						c.children && c.children.map(d => {
							arr.push(d.id)
						})
					})
				})
			})
			// console.log(arr)
			return arr
		},
		checkAllCo(index) {
			this.indexCo = index
			this.ruleForm.moban_arr[index].checkAll = !this.ruleForm.moban_arr[index].checkAll
			this.ruleForm.moban_arr[index].isIndeterminate = false
			this.ruleForm.moban_arr[index].selectCoList = this.coList
		},
		openCoList(index) {
			let _this = this
			_this.indexCo = index
			this.showDialog_co = true
			_this.$nextTick(function () {
				_this.$refs.coMuliTable.clearSelection()
				let arr = _this.ruleForm.moban_arr[index].selectCoList
				if (arr && arr.length > 0) {
					arr.forEach((item, index) => {
						_this.$refs.coMuliTable.toggleRowSelection(item, true);
					});
				}
			})
		},
		muliSelectTableCo(e) {
			// console.log(e)
			let index = this.indexCo
			this.ruleForm.moban_arr[index].selectCoList = e
		},
		muliSelectTableCo_all(e) {
			// console.log(e)
			let index = this.indexCo
			this.ruleForm.moban_arr[index].selectCoList = e
		},
		closeCoList() {
			let _this = this
			let index = this.indexCo
			let checkedCount = this.ruleForm.moban_arr[index].selectCoList.length;
			this.ruleForm.moban_arr[index].checkAll = checkedCount === this.coList.length;
			this.ruleForm.moban_arr[index].isIndeterminate = checkedCount > 0 && checkedCount < this.coList.length;

			_this.showDialog_co = false
		},
		onSubmit(formName) {
			let _this = this

			let params = JSON.parse(JSON.stringify(_this.ruleForm));


			if (!params.plan_name) {
				this.$message.warning(`请填写问卷名称！`)
				return
			}
			if (!params.sta_end_time) {
				this.$message.warning(`选择调查时段！`)
				return
			}
			if (!params.moban_arr || params.moban_arr.length == 0) {
				this.$message.warning('请添加一条模板！')
				return
			}
			if (!this.target_classes || !this.target_teachers) {
				this.$message.warning('请选择发送对象！')
				return
			}

			if (params.formal == null || params.formal == undefined) {
				this.$message.warning(`选择发布状态！`)
				return
			}
			params.target_classes = this.target_classes
			params.target_teachers = this.target_teachers
			//检查是否有重复模板
			let ivstDic = {};
			for (let ivst of params.moban_arr) {
				if (ivstDic[ivst.ivst_id]) {
					this.$message.error("不能选择重复模板")
					return
				} else {
					ivstDic[ivst.ivst_id] = 1
				}
			}


			this.$http.post("/api/by_ivst_plan_edit", params).then((res) => {
				if (res.data.code == 200) {
					this.$message.success('提交成功！')
					this.showDialog = false;
					this.getlist();
				} else {
					this.$message.error(res.data.msg)
				}
			});


		},
		exportReport(filename) {
			this.$message.warning("正在生成文件，请耐心等待！")
			let url = window.location.href.split("plan")[0]
			url = url + "report?auth=" + sessionStorage.getItem("auth") + "&plan_id=" + this.plan_id
			this.$http.post("/api/print_report", {
				url: url,
				type: "pdf",
				showloading: true
			}).then((res) => {
				window.open(res.data.url, "_blank")
			});
		},
		createMiniQrcode(row){

			this.$http.post("/api/plan_qrcode_mini", {
				id:row.id
			}).then((res) => {
				 this.getlist()
				 this.$message.success("生成成功")
			});
			
		},
		createH5Qrcode(row){
			this.$http.post("/api/plan_qrcode_h5", {
				id:row.id,
				qrcode_url:"https://sx.qushixi.org.cn/#/h5_ivst_plan?pid="+row.id+"&pmid="+window.pmid+"&chs=1"

			}).then((res) => {
				 this.getlist()
				 this.$message.success("生成成功")
			});
		},
		downImage(e) {
			let that = this;
			if(e.qrcode&&e.qrcode.indexOf("qushixi")>0){
				
				setTimeout(() => {
					download()
				}, 50);
				
			}else if(e.qrcode){
				window.open(e.qrcode,"_blank")
			}
			
			
			

			function downloadFile(fileName, content) {
				let aLink = document.createElement('a');
				let blob = base64ToBlob(content); //new Blob([content]);

				let evt = document.createEvent("HTMLEvents");
				evt.initEvent("click", true, true); //initEvent 不加后两个参数在FF下会报错  事件类型，是否冒泡，是否阻止浏览器的默认行为
				aLink.download = fileName;
				aLink.href = URL.createObjectURL(blob);

				// aLink.dispatchEvent(evt);
				aLink.click()
			}
			//base64转blob
			function base64ToBlob(code) {
				let parts = code.split(';base64,');
				let contentType = parts[0].split(':')[1];
				let raw = window.atob(parts[1]);
				let rawLength = raw.length;

				let uInt8Array = new Uint8Array(rawLength);

				for (let i = 0; i < rawLength; ++i) {
					uInt8Array[i] = raw.charCodeAt(i);
				}
				return new Blob([uInt8Array], {
					type: contentType
				});
			}

			function download() {
				let title = "";
				for (let item of that.dataList) {
					if (item.id == e) {
						title = item.plan_name
					}
				}
				let imgData = $("#qrcode_" + e.id).find("img").attr("src");
				downloadFile(title + ".png", imgData);
			}
			


		},
		setTarget(e) {
			if (e.teachers && e.teachers.length > 0) {
				this.choosedTeachers = e.teachers.length
				this.target_teachers = "0," + e.teachers.join(',') + ",0"
			} else {
				this.target_teachers = "0"
				this.choosedTeachers = 0
			}

			if (e.classes && e.classes.length > 0) {
				this.target_classes = "0," + e.classes.join(',') + ",0"
				this.choosedClasses = e.classes.length
			} else {
				this.target_classes = "0"
				this.choosedClasses = 0
			}


		},
		sendRemind(e) {
			this.formData = e
			this.showSms = true
			// this.$confirm('是否立即发送未参与用户提醒通知?', '提示', {
			// 	confirmButtonText: '确定',
			// 	cancelButtonText: '取消',
			// 	type: 'warning'
			// }).then(() => {
			// 	this.$http.post("/api/ivstplan_sendremind", { plan_id: e.id }).then(res => {
			// 		this.$message({
			// 			type: 'success',
			// 			message: '提醒发送中(' + res.data.count + '条)，请勿多次点击!'
			// 		});
			// 	})

			// }).catch(() => {

			// });
		},
		createQrcode(e) {

			console.log(e)
			this.$http.post("/api/create_plan_qrcode", { id: e.id }).then(res => {
				this.getlist();
			})

		},
		createQrcodeNew(e) {
			let qrcodetxt = window.location.href.split("#")[0] + "#/p/" + e.pmid + "$" + e.id + "$s$0$m" + '?pmid=' + e.pmid
			// console.log(526,qrcodetxt)
			var qrcode = new QRCode("qrcode_" + e.id, {
				text: qrcodetxt, // 需要转换为二维码的内容
				width: 500,
				height: 500,
				colorDark: '#000000',
				colorLight: '#ffffff',
				correctLevel: QRCode.CorrectLevel.H
			})

		},
	},
};
</script>
<style type="text/less" lang="less">
.requiredLabel {

	.el-form-item__label {
		position: relative;
	}

	.el-form-item__label::before {
		content: '*';
		color: #ff5500;
		position: absolute;
		left: -2px;
	}
}

.cus_dialog_complete {
	width: 90%;
	height: 80vh;
	margin-top: 5vh !important;

	div {
		box-sizing: border-box
	}

	.el-dialog__body {
		display: flex;
		justify-content: space-between;
		align-items: stretch;
		height: calc(100% - 44px);

		.reportBox {
			height: 100%;

			.el-tabs {
				height: 100%;

				.el-tabs__content {
					height: calc(100% - 55px);
				}
			}

			.thumbnail {
				width: 100%;
				height: 100%;

				img {
					display: inline-block;
					max-width: 100%;
					max-height: 100%;
				}
			}
		}

		.el-tree-node__content {
			width: 100%;
			//background-color: pink;
		}
	}
}

.cus_dialog_preview {
	//width: 375px;
	//margin-top: 10vh;
	width: 1200px;
	height: 78vh;

	.el-dialog__body {
		height: calc(100% - 120px);

		/*滚动条背景*/
		::-webkit-scrollbar-track {
			background-color: #f8f8f8;
			border-radius: 6px;
		}

		.previewBox {
			height: 100%;

			.left {
				height: 100%;
				overflow-y: scroll;

				.tmItem {
					background-color: #f8f8f8;
					margin-bottom: 15px;
					padding: 10px;
					box-sizing: border-box;
					border-left: 5px solid #409eff;

					.tmTitle {
						font-size: 16px;
					}

					.tmOptions {
						font-size: 14px;
						flex-wrap: wrap;
					}
				}
			}

			.right {
				height: 100%;
				overflow-y: scroll;

			}
		}
	}
}


.cus_dialog_report {
	width: 90%;
	height: 90vh;
	margin-top: 5vh !important;

	.el-dialog__body {
		height: calc(100% - 120px);

		.reportBox {
			height: 100%;

			.el-tabs {
				height: 100%;

				.el-tabs__content {
					height: calc(100% - 55px);
				}
			}

			.thumbnail {
				width: 100%;
				height: 100%;

				img {
					display: inline-block;
					max-width: 100%;
					max-height: 100%;
				}
			}
		}
	}

	.boxitem {
		width: 220px;
		height: 90px;
		display: inline-block;
		background-image: url(../../../public/img/bx_bg.png);
		background-size: 100% 100%;
		margin-bottom: 20px;
		color: #4dadea;
		font-size: 16px;

		.boxdesc {
			display: flex;
			justify-content: center;
			align-items: center;
			height: 100%;
		}
	}

}

.words {
	padding: 5px 10px;
	margin: 0 20px;
	text-align: center;
	border-bottom: 1px solid #ccc;
}

.qrcode img {
	width: 100%;
	height: 100%;
}
</style>
